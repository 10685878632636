import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";

function ContactPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    window.analytics.identify(email, {
      first_name: firstName,
      last_name: lastName,
      company,
      email,
    });
    window.analytics.group(company, {
      name: company,
      email,
    });
    // window.analytics.track("contact submitted", {
    //   first_name: firstName,
    //   last_name: lastName,
    //   company,
    //   email,
    //   message,
    // });
    window.analytics.track("account created", {
      first_name: firstName,
      last_name: lastName,
      company,
      email,
      message,
    });
    setSubmitted(true);
  };

  const [ajsAnonymousId, setAjsAnonymousId] = useState("");
  const [ampDeviceId, setAmpDeviceId] = useState("");

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      setAjsAnonymousId(window.analytics.user().anonymousId());
      setAmpDeviceId(window.amplitude.options.deviceId); // cloud mode
      //setAmpDeviceId(window.amplitude.getInstance().getDeviceId())
    }
  }, []);

  const handleSecretButtonClick = () => {
    // window.analytics.track("secret clicked", {});
    window.analytics.track("onboarding progressed", {
      step: 2,
    });
    email
      ? (window.location =
          "http://localhost:3000/?ajs_id=" + email)
      : (window.location =
          "http://localhost:3000/?ajs_anonymous_id=" + ajsAnonymousId + "&amp_device_id=" + ampDeviceId);
  };
  return (
    <Layout>
      <SEO keywords={[`spectatin contact`]} title="Contact" />
      <section className="mt-12">
        <form className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <label
            // className="block mb-2 text-xs font-bold uppercase"
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="first-name"
          >
            First Name
          </label>

          <input
            className="w-full mb-6 form-input"
            id="first-name"
            placeholder="Bill"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <label
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="last-name"
          >
            Last Name
          </label>

          <input
            className="w-full mb-6 form-input"
            id="last-name"
            placeholder="Murray"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <label
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="company"
          >
            Company
          </label>

          <input
            className="w-full mb-6 form-input"
            id="company"
            placeholder="Apple"
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />

          <label
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="email"
          >
            Work Email
          </label>

          <input
            className="w-full mb-6 form-input"
            id="email"
            placeholder="bill@murray.com"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label
            className="text-lg leading-6 font-medium text-gray-900"
            htmlFor="message"
          >
            Message
          </label>

          <textarea
            className="w-full mb-6 form-textarea"
            id="message"
            placeholder="Say something..."
            rows="8"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Button onClick={() => handleSubmit()}>
            {submitted ? "Thanks! I will email you soon." : "Submit"}
          </Button>
          {submitted && company === "test" ? (
            <div className="">
              <Button onClick={() => handleSecretButtonClick()}>
                Continue secret onboarding...
              </Button>
              <a href="https://jamies-fabulous-project-9f8a08.webflow.io/">webflow referrer test</a>
            </div>
          ) : null}
        </form>
      </section>
    </Layout>
  );
}

export default ContactPage;
